import { createStore } from "vuex";
import { useToast } from "vue-toastification";
import modules from "./modules";
import axios from "@/assets/js/axios";

const toast = useToast();

export default createStore({
  modules,
  state: () => ({
    menuMobile: false,
    customersTypes: [],
    bussinessTypes: [],
    productsTypes: [],
    observationsTypes: [],
    atmosphere: [],
  }),
  getters: {},
  mutations: {
    TOGGLE_MENU(state, value) {
      state.menuMobile = value;
    },
    SET_STATE(state, { prop, modules, data }) {
      const target = modules ? state[modules] : state;
      target[prop] = data;
    },
    CREATE_ROWS(state, { prop, modules, data, reverse, index }) {
      const target = modules ? state[modules] : state;
      console.log(index);
      if (typeof index == "number") {
        target[prop].splice(index, 0, data);
      } else {
        if (reverse) target[prop].unshift(data);
        else target[prop].push(data);
      }
    },
    UPDATE_ROWS(state, { prop, modules, data }) {
      const target = modules ? state[modules] : state;
      for (let item of target[prop])
        if (item.id == data.id) Object.assign(item, data);
    },
    DELETE_ROWS(state, { prop, modules, id, data }) {
      const target = modules ? state[modules] : state;
      if (!data) target[prop] = target[prop].filter((item) => item.id != id);
      else
        target[prop].forEach((item) => {
          if (item.id == id) Object.assign(item, data);
        });
    },
  },
  actions: {
    async getRow(ctx, settings) {
      const { url, id, map } = settings;
      const { status, data: res } = await axios({
        url: `${url}/${id}`,
        method: "get",
        dispatch: "getRow",
        settings,
      }).catch((e) => e.response);
      let data = null;

      switch (status) {
        case 200:
          data = res.row;
          if (map) data = map(data);

          return data;

        default:
          toast.error(res.message);
      }
    },
    async getRows({ commit }, settings) {
      const { url, prop, modules, map } = settings;
      const { status, data: res } = await axios({
        url,
        method: "get",
        dispatch: "getRows",
        settings,
      }).catch((e) => e.response);
      let data = null;

      switch (status) {
        case 200:
          data = res.rows;
          if (map) data = map(data);

          commit("SET_STATE", { prop, modules, data });
          return { status, res };
      }
    },

    async createRow({ commit }, settings) {
      const { url, prop, modules, data, reverse, add = true, index } = settings;
      const { status, data: res } = await axios({
        url,
        method: "post",
        dispatch: "createRow",
        data,
        settings,
      }).catch((e) => e.response);

      if (status === 200) {
        if (add)
          commit("CREATE_ROWS", {
            prop,
            modules,
            reverse,
            index,
            data: res.row,
          });

        toast.success(res.message);
        return { status, data: res.row };
      } else {
        toast.error(res.message);
      }
    },

    async updateRow({ commit }, settings) {
      const { url, prop, modules, data, id } = settings;
      const { status, data: res } = await axios({
        url: id ? `${url}/${id}` : url,
        method: "patch",
        data,
        dispatch: "updateRow",
        settings,
      }).catch((e) => e.response);

      switch (status) {
        case 200:
          commit("UPDATE_ROWS", { prop, modules, data: res.row });
          toast.success(res.message);
          return { status, data: res.row };
        case 210:
          toast.info(res.message);
          return { status, data: null };
        default:
          toast.error(res.message);
      }
    },

    async updateRows({ commit }, settings) {
      const { url, prop, modules, data } = settings;
      const { status, data: res } = await axios({
        url: `${url}`,
        method: "put",
        data,
        dispatch: "updateRows",
        settings,
      }).catch((e) => {
        console.log(e);
        return e.response;
      });

      switch (status) {
        case 200:
          commit("SET_STATE", { prop, modules, data: res.rows });
          toast.success(res.message);
          break;
        default:
          toast.error(res.message);
      }
      return { status, res };
    },

    async deleteRow({ commit }, settings) {
      const { url, prop, modules, id } = settings;
      const { status, data: res } = await axios({
        url: `${url}/${id}`,
        method: "delete",
        dispatch: "deleteRow",
      }).catch((e) => e.response);

      switch (status) {
        case 200:
          commit("DELETE_ROWS", { prop, modules, id, data: res.row });
          toast.success(res.message);
          return true;
        default:
          toast.error(res.message);
      }
    },

    async getOptions({ commit, state }, settings) {
      const { url, prop, modules, map } = settings;
      const stateProp = modules ? state[modules][prop] : state[prop];
      if (stateProp.length == 0) {
        const { status, data: res } = await axios({
          url,
          method: "get",
          dispatch: "getOptions",
        }).catch((e) => e.response);

        let data = null;

        switch (status) {
          case 200:
            data = res.rows;
            if (map) data = map(data);
            commit("SET_STATE", { prop, data, modules });
            break;
        }
      }
    },
  },
});
